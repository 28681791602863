export function CSP_PrivacyPolicy() {
  return (
    <>
      <div className="normal-text">

        <h2>
          Privacy Policy
        </h2>

        <p>
          This privacy policy (&quot;policy&quot;) will help you understand how Muhre Software Solutions (&quot;us&quot;, &quot;we&quot;, &quot;our&quot;)
          uses and protects the data you provide to us when you use <b>CRM & Sales Perspectives</b> visualizations and analytics for monday.com (&quot;application&quot;, &quot;service&quot;).
        </p>

        <p>
          We reserve the right to change this policy at any given time, of which you will be promptly updated.
          If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.
        </p>

        <h3>
          What User Data We Collect
        </h3>

        <p>
          When you use the app, we may collect the following data:
        </p>

        <ul>
          <li>User information related to your monday.com subscription to use our application
            (including the following: app id, user id, user email, user name, user cluster, account tier,
            account name, account slug, account max users, account id, version data, timestamp, subscription, user country).
            All data stored on our database is encrypted.</li>
          <li>Performance and error logging data related to usage of our application.</li>
        </ul>

        <h3>
          Why We Collect Your Data
        </h3>

        <p>
          We are collecting your data for several reasons:
        </p>

        <ul>
          <li>To better understand your needs.</li>
          <li>To improve our services and products.</li>
        </ul>

        <h3>
          Safeguarding and Securing the Data
        </h3>

        <p>
          Muhre Software Solutions is committed to securing your data and keeping it confidential.
          Muhre Software Solutions has done all in its power to prevent data theft, unauthorized access,
          and disclosure by implementing the latest technologies and software, which help us safeguard all the information we collect online.
        </p>

        <h3>
          Restricting the Collection of your Personal Data
        </h3>

        <p>
          Muhre Software Solutions will not lease, sell, or distribute your personal information to any third parties, unless we have your permission.
          We might do so if the law forces us. Your personal information will be used when we need to send you promotional materials if you agree to this privacy policy.
        </p>

      </div>
    </>
  );
}
