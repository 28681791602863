import Logo from "./assets/logo (oblong sphere) blurred.png"
import IPC_Sample from "./assets/ipc_app_card_image.png"
import CSP_Sample from "./assets/csp_app_card_image.png"

export function MainPage() {
  return (
    <>
      <div>

        <p className="centered">
          <img width="900" height="350" src={Logo} /></p>

        <p ><span style={{ "fontSize": "16px", "lineHeight": "125%" }} >&nbsp;</span></p>

        <table width="90%" align="center"><tbody>
          <tr>
            <td width="45%">
              <p className="centered">
                <b><span style={{ "fontSize": "28px", "lineHeight": "100%", "color": "#5F0000" }} >Interactive Path Chart</span>
                  <br />
                  <span style={{ "fontSize": "20px", "lineHeight": "100%", "color": "#005F00" }} >view for </span>
                  <span style={{ "fontSize": "24px", "lineHeight": "100%", "color": "black" }} >monday.com</span>
                  <br />
                  <span style={{ "fontSize": "20px", "lineHeight": "100%", "color": "#005F00" }} >dependency-based project boards</span></b></p>
            </td>
            <td width="10%">
              <p ><span style={{ "fontSize": "16px", "lineHeight": "125%" }} >&nbsp;</span></p>
            </td>
            <td width="45%">
              <p className="centered">
                <b><span style={{ "fontSize": "28px", "lineHeight": "100%", "color": "#5F0000" }} >CRM & Sales Perspectives</span>
                  <br />
                  <span style={{ "fontSize": "20px", "lineHeight": "100%", "color": "#005F00" }} >multi-faceted visualizations for </span>
                  <span style={{ "fontSize": "24px", "lineHeight": "100%", "color": "black" }} >monday.com</span>
                  <br />
                  <span style={{ "fontSize": "20px", "lineHeight": "100%", "color": "#005F00" }} >CRM deals boards</span></b></p>
            </td>
          </tr>
          <tr>
            <td width="45%">
              <p className="centered">
                <a href="https://monday.com/marketplace/listing/10000519/interactive-path-chart"><img width="100%" src={IPC_Sample} /></a></p>
            </td>
            <td width="10%">
              <p ><span style={{ "fontSize": "16px", "lineHeight": "125%" }} >&nbsp;</span></p>
            </td>
            <td width="45%">
              <p className="centered">
                <a href="https://monday.com/marketplace/listing/10000614/crm--sales-perspectives"><img width="100%" src={CSP_Sample} /></a></p>
            </td>
          </tr>
        </tbody></table>

        <p ><span style={{ "fontSize": "16px", "lineHeight": "125%" }} >&nbsp;</span></p>

        <p className="centered">
          <span style={{ "fontSize": "24px", "lineHeight": "125%" }} >
            contact Muhre Software Solutions at <a href="mailto:inquiries@muhre.com">inquiries@muhre.com</a></span></p>

      </div>
    </>
  );
}
