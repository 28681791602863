export function CSP_TermsOfService() {
  return (
    <>
      <div className="normal-text">

        <h2>
          Terms of Service
        </h2>

        <p>
          Please read these terms of service (&quot;terms of service&quot;, &quot;terms&quot;) carefully before using
          <b>CRM & Sales Perspectives</b> visualizations and analytics for monday.com
          developed by Muhre Software Solutions (&quot;us&quot;, &quot;we&quot;, &quot;our&quot;).
        </p>

        <h3>
          Conditions of use
        </h3>

        <p>
          By using this application, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms.
          If you do not want to be bound by the terms of this Agreement, you are advised to leave the application accordingly.
          Muhre Software Solutions only grants use and access of this application to those who have accepted its terms.
        </p>

        <h3>
          Privacy policy
        </h3>

        <p>
          Before you continue using our application, we advise you to read our privacy policy [link to privacy policy] regarding our user data collection.
          It will help you better understand our practices.
        </p>

        <h3>
          Intellectual property
        </h3>

        <p>
          You agree that all services provided in this application are the property of Muhre Software Solutions,
          its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights,
          trade secrets, trademarks, patents, and other intellectual property.
          You also agree that you will not reproduce or redistribute Muhre Software Solution&apos;s intellectual property in any way,
          including electronic, digital, or new trademark registrations.
        </p>

        <p>
          For issues regarding intellectual property claims, you should contact the company to come to an agreement.
        </p>

        <h3>
          User accounts
        </h3>

        <p>
          As a user of this application, you may be asked to register with us and provide private information.
          You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining
          the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.
        </p>

        <p>
          If you think there are any possible issues regarding the security of your account on the application,
          inform us immediately so we may address it accordingly.
        </p>

        <p>
          We reserve all rights to terminate accounts, edit or remove content and cancel orders in their sole discretion.
        </p>

        <h3>
          Applicable law
        </h3>

        <p>
          By using this application, you agree that the laws of Connecticut, without regard to principles of conflict laws,
          will govern these terms and conditions, or any dispute of any sort that might come between Muhre Software Solutions and you,
          or its business partners and associates.
        </p>

        <h3>
          Disputes
        </h3>

        <p>
          Any dispute related in any way to your use of this application you purchase from us shall be arbitrated by state or federal court
          in Connecticut and you consent to exclusive jurisdiction and venue of such courts.
        </p>

        <h3>
          Indemnification
        </h3>

        <p>
          You agree to indemnify Muhre Software Solutions and its affiliates and hold Muhre Software Solutions harmless against legal claims
          and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.
        </p>

        <h3>
          Limitation on liability
        </h3>

        <p>
          Muhre Software Solutions is not liable for any damages that may occur to you because of your misuse of our application.
        </p>

        <p>
          Muhre Software Solutions reserves the right to edit, modify, and change this Agreement any time.
          We shall let our users know of these changes through electronic mail.
          This Agreement is an understanding between Muhre Software Solutions and the user, and this supersedes and replaces all prior agreements regarding the use of this website.
        </p>

      </div>
    </>
  );
}
