import Image002 from "../assets/ipc_image002.png"
import Image004 from "../assets/ipc_image004.jpg"
import Image005 from "../assets/ipc_image005.png"
import Image007 from "../assets/ipc_image007.png"
import Image009 from "../assets/ipc_image009.png"
import Image010 from "../assets/ipc_image010.png"
import Image013 from "../assets/ipc_image013.png"

export function IPC_Monday() {
	return (
		<>
			<div className="normal-text">

				<h1>
					<img width="62" height="62" src={Image002} />
					<span style={{ "color": "#005F00", "paddingLeft": "20px" }} >Interactive Path Chart</span>
				</h1>

				<h2>
					<span style={{ "color": "#733635" }} >
						view for monday.com timeline and dependency-based project boards
					</span>
				</h2>

				<h3>
					<span>
						by Muhre Software Solutions
					</span>
				</h3>

				<p>&nbsp;</p>

				<h2>Introduction</h2>

				<p>
					Muhre&apos;s Interactive Path Chart (IP Chart) view for monday.com timeline and dependency-based project boards works
					with any monday.com board that contains at least <b>one timeline column AND one	dependency column</b>. IP Chart
					allows users to easily view, manipulate, and edit project timelines and dependencies within a visual layout of
					the data,	arranged by relative (compact) or actual start times and durations.
				</p>

				<p>&nbsp;</p>

				<h2>Installation</h2>

				<p>
					Simply add the application as a view (from <img width="21" height="15" src={Image004} />Apps),
						wait for your board to load, and the Interactive Path Chart is ready for use.
				</p>

				<p>&nbsp;</p>

				<h2>Prerequisites</h2>

				<p>Your associated monday.com board must contain at least <b>one
					timeline column AND one dependency column</b>. If your board contains more than one
					of either column type, you can select which to use in the app settings panel.</p>

				<p>&nbsp;</p>

				<h2>Usage and Instructions</h2>

				<h4>Customize View</h4>

				<p style={{ "float": "left", "paddingRight": "30px" }} >
					<img width="255" height="574" src={Image005} />
				</p>

				<p style={{ "float": "none", "paddingTop": "20px" }} >
					Customize View in the app settings panel located to the right of the chart view allows options
					for selecting the timeline and dependencies column from your board, setting the layout scaling,
					limiting the view to a specific group, and saving date and dependency changes.
				</p>

				<p>&nbsp;</p>

				<h4>Timeline Column</h4>

				<p>
					If you have more than one timeline column in your associated board, you can choose which one is
					to be used in IP Chart. Item start dates and durations are derived from the selected column.
				</p>

				<p>&nbsp;</p>

				<h4>Dependencies Column</h4>

				<p>
					If you have more than one dependency column in your associated board, you can choose which one is
					to be used in IP Chart. Item dependency relationships are derived from the selected column.
				</p>

				<p>&nbsp;</p>

				<h4>Scaling</h4>

				<p>
					There are two options to choose from in setting the layout scaling of the view.
					&quot;Compact scale&quot; eliminates empty time gaps and represents the timeline as the minimum number of days to project completion.
					&quot;Scale to actual start dates&quot; arranges items across the view according to actual start and end dates.
					Items with no specified start and end dates are assigned the current date (today) and a duration of one day.
				</p>

				<p>&nbsp;</p>

				<h4>Group</h4>

				<p>
					For ease of viewing and editing, the layout can be limited to only board items belonging to a specific group.
					This setting takes effect only when the &quot;Display all groups&quot; override is NOT selected (off).
				</p>

				<p>&nbsp;</p>

				<h4>Display all groups</h4>

				<p>
					When this checkbox is selected (on), all board items will be displayed in the chart.
					This overrides individual group selection.
				</p>

				<p>&nbsp;</p>

				<h4>Save Changes</h4>

				<p>
					Clicking the Save Changes button will transfer all manual changes to the associated monday.com board.
					Only the timeline (dates) and dependency columns are updated; no other columns are affected.
					NOTE: The user must have administrator privileges to save changes.
				</p>

				<div style={{ "clear": "both" }} ></div >

				<p>&nbsp;</p>

				<h4>Item Count Limitation</h4>

				<p>
					<img width="705" height="148" src={Image010} />
				</p>

				<p>
					While there is no limit to the number of board items that can be loaded into IP Chart,
					app performance and usability will diminish with the number of items promoted (made active) to the view.
					Therfore, IP Chart limits the number of active items. Use the board grouping options and the group
					selections in the app settings panel to avoid running into this limitation. The limitation warning
					is presented only when the number of promoted (active) items exceeds the limit.
				</p>

				<p>&nbsp;</p>

				<h4>Layout</h4>

				<p>
					<img width="783" height="222" src={Image007} />
				</p>

				<p>
					IP Chart&apos;s optimal layout calculation places the &quot;critical&quot; or longest path at the vertical center of the grid.
					All other paths are placed alternately above or below this with longer paths closer to the center
					and shorter paths further from the center.
				</p>

				<p>
					IP Chart recalculates an optimal layout each time the settings or set of viewed items changes
					(e.g., column changes, scaling changes, saving changes, group selection, changes to the associated board).
					Specific placement of items is not maintained between layouts.
				</p>

				<p>
					The labeled timeline axis shown at the top of the layout displays the number of days from the start of the
					project when using compact scale or the date-based timeline when using actual start dates scale.
				</p>

				<p>
					Each project item is represented by a linked group of elements: a circular node colored according to the item&apos;s group,
					an arrow representing the time range of the item, and an information label. The information label is divided into three sections:
					a shaded box representing the item&apos;s color-coded owner, the item&apos;s name or title, and a shaded black box containing the item&apos;s
					timeline duration. A dashed line is used to represent dependencies for items that are not connected end-to-start.
				</p>

				<p>
					Project items are allowed to occupy the same grid location. In such cases, a box appears to the right of the node symbol,
					indicating the number of items presently sharing that node.
				</p>

				<p style={{ "float": "left", "paddingRight": "20px", "paddingLeft": "50px" }} >
					<img width="50" height="100" src={Image013} />
				</p>

				<p style={{ "paddingTop": "20px" }} >
					Two icon buttons are located in the lower-left corner of the view window:
				</p>

				<ul style={{ "listStylePosition": "inside" }} >
					<li>The <b>Refresh</b> button on top will force a recalculation of the view to an optimal layout.</li>
					<li>The <b>Help</b> button below will launch this documentation page in a separate tab window.</li>
				</ul>

				<div style={{ "clear": "both" }} ></div>

				<p>&nbsp;</p>

				<h4>Legend</h4>

				<p style={{ "float": "left", "paddingRight": "20px" }} >
					<img width="329" height="137" src={Image009} />
				</p>

				<p style={{ "paddingTop": "20px" }} >
					A simple color-coded legend can be found at the bottom of the view, below the layout,
					showing all the groups and owners currently displayed.
				</p>

				<div style={{ "clear": "both" }} ></div>

				<p>&nbsp;</p>

				<h4>Changing Dates</h4>

				<ol>
					<li>Click on the information label of the item you want to change.</li>
					<li>In the popup information dialog that displays, click on the new start and end
						dates (click on the date twice if the item starts and ends on the same day).</li>
					<li>Click anywhere inside or outside the dialog to close it.</li>
				</ol>

				<p style={{ "marginLeft": ".25in" }} >
					<video width="640" height="480" controls>
						<source src="mondaydoc_files/dates_change.mp4" type="video/mp4" />
					</video>
				</p>

				<p>&nbsp;</p>

				<h4>Moving Items</h4>

				<ol>
					<li>Click and hold the mouse button down over the item&apos;s node (circle).</li>
					<li>Drag the item to a new location within the layout grid.</li>
					<li>Release the mouse button to disengage. The item will snap to the nearest layout grid intersection. Other items dependent
						on the moved item will be automatically adjusted, if necessary, to maintain their sequential order on the timeline.</li>
				</ol>

				<p style={{ "marginLeft": ".25in" }} >
					<video width="640" height="480" controls>
						<source src="mondaydoc_files/assign_dependencies.mp4" type="video/mp4" />
					</video>
				</p>

				<p>&nbsp;</p>

				<h4>Creating Dependencies</h4>

				<p>
					If an item is moved such that its node snaps to the terminus (arrow) of another item,
					the two items will be assigned a dependency relationship.
				</p>

				<p>&nbsp;</p>

				<h4>Breaking Dependencies</h4>

				<p>
					If a dependent item is moved to a position that precedes the terminus (arrow) of its dependency,
					the dependency relationship is removed.
				</p>

				<p>&nbsp;</p>

			</div>
    </>
  );
}
